import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Evaluations({ isWidget }) {
  // AUTH, STATE, NAVIGATION
  const { authobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleAppointment, handleSuspension, handleRework } = useHelpers();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [evaluations, setEvaluations] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentNote, setAppointmentNote] = useState(null);
  // FILTER & SEARCH
  const [query, setQuery] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [orderField, setOrderField] = useState('id_commessa');
  const [orderType, setOrderType] = useState('ASC');

  useEffect(() => {
    if (state && state.create_new)
      navigate('/evaluations/add');

    axios.post(window.SERVER_URL+'evaluation_statuses/list', { params: { 'light': true } }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setStatuses(res.data.statuses);

      // document.getElementById('query').value = state && state.query ? state.query : query;
      getPage(
        state && state.page ? state.page : page,
        state && state.query ? state.query : query
      );
    });
  }, [globalRefresh]);

  const retrieveEvaluations = (params) => {
    if (params && params.page) {
      axios.post(window.SERVER_URL+'evaluations/list', { params }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setEvaluations(res.data.evaluations);
        setPage(params.page);
        setNumPages(res.data.pages);
        setCount(res.data.count);
      });
    }
  }

  const getPage = (i, params) => {
    if (!params)
      var params = {
        page: i,
        search: {
          query: (query && query.length >= 3 ? query : ''),
          stato: selectedStatus ? selectedStatus.value : ''
        },
        order: {
          field: orderField,
          type: orderType
        }
      }
    console.log("PARAMS:");
    console.log(params); 
    retrieveEvaluations(params);
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    setQuery(typed);
    var params = {
      page: 1,
      search: {
        query: (typed && typed.length >= 3 ? typed : ''),
        stato: selectedStatus ? selectedStatus : ''
      },
      order: {
        field: orderField,
        type: orderType
      }
    }
    getPage(1, params);
  }

  const handleChangeSelectedStatus = (status) => {
    console.log("STATUS: "+status);
    setSelectedStatus(status);
    var params = {
      page: 1,
      search: {
        query: (query && query.length >= 3 ? query : ''),
        stato: status ? status : ''
      },
      order: {
        field: orderField,
        type: orderType
      }
    }
    getPage(1, params);
  }

  const handleChangeOrder = (field) => {
    console.log("CHANGED ORDER");
    setOrderField(field);
    let tmpType = (field == orderField ? (orderType == 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    setOrderType(tmpType);
    var params = {
      page: 1,
      search: {
        query: (query && query.length >= 3 ? query : ''),
        stato: selectedStatus ? selectedStatus : ''
      },
      order: {
        field: field ? field : 'id',
        type: tmpType
      }
    }
    getPage(1, params);
  }

  const handleAssignment = (evaluation_id, id_commessa) => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
            <h2>Conferma presa in carico</h2><br>
            <p>Vuoi confermare la presa in carico della<br>pratica con <b>ID ${id_commessa}</b></p>
            `,
      width: '800px',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sì, lo confermo',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'No, non lo confermo',
      confirmButtonColor: '#12275E',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'evaluations/assign', { evaluation_id: evaluation_id, user_id: authobj.user.id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // se l'assegnazione è andata a buon fine
            setGlobalRefresh();
            Swal.fire({
              html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
                    <h2>OTTIMO LAVORO!</h2><br>
                    <p>La tua azione è andata a buon fine</p>
                    `,
              width: '800px',
              confirmButtonText: (authobj.user.role.slug == "qual-cont" ? 'Inizia il controllo' : (authobj.user.role.slug == "qual-val" ? 'Inizia la validazione' : '')),
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonColor: '#12275E',
            }).then((result) => {
              if (result.isConfirmed) {
                if (authobj.user.role.slug == "qual-cont")
                  navigate('/evaluations/con', { state: { evaluation_id: evaluation_id } });
                if (authobj.user.role.slug == "qual-val")
                  navigate('/evaluations/val', { state: { evaluation_id: evaluation_id } });
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile prendere in carico la pratica. Potresti averne già una presa in carico manualmente."
            });
          }
        });
      }
    });
  }

  const handleDelete = (evaluation_id, id_commessa) => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
            <h2>Conferma annullamento pratica</h2><br>
            <p>Vuoi davvero annullare la<br>pratica con <b>ID ${id_commessa}</b></p>
            `,
      width: '800px',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sì, lo confermo',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'No, non lo confermo',
      confirmButtonColor: '#12275E',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'evaluations/delete', { id: evaluation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // se la cancellazione è andata a buon fine
            setEvaluations(evaluations.filter(evaluation => evaluation.id !== evaluation_id));
            // getPage(1, query);
            setGlobalRefresh();
            Swal.fire({
              html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
                    <h2>Pratica annullata</h2><br>
                    <p>La pratica è stata annullata correttamente</b></p>
                    `,
              width: '800px',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile annullare la pratica"
            });
          }
        });
      }
    });
  }
  
  return (
    <>
      <Container className={ isWidget === true ? "content noMargin" : 'content' }>
        { isWidget !== true ?
          <>
            <Row className="page_title">
              <Col>
                <h1>Pratiche</h1>
              </Col>
            </Row>
            { ['superadmin','back-a'].includes(authobj.user.role.slug) ?
              <Row className="action_bar top">
                <Col>
                  <Button variant="success" onClick={() => { navigate('/evaluations/add', {state: {page: page, query: query}}); }}><DynamicIcon iconName='BiPlus' /> Nuova pratica</Button>
                </Col>
              </Row>
            : '' }
          </>
        : '' }
        <Row>
          <Col id="list_filter">
            <div>
              <form className="custom_form" id="query_form">
                <input type="text" placeholder="Cerca pratica" id="query" value={query} onChange={(e) => { handleInputChange(e.target.value) }} />
                <DynamicIcon iconName='FaSearch' />
              </form>
            </div>
            <div>
              <select onChange={(e) => handleChangeSelectedStatus(e.target.value)}>
                <option value="">- Tutti gli stati -</option>
                { statuses ? statuses.map(s => 
                  <option key={ s.id } value={ s.id }>{ s.descrizione }</option>
                ) : '' }
              </select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='tab_wrapper'>
            { evaluations ? 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th onClick={() => { handleChangeOrder('id_commessa') }}>ID { orderField == 'id_commessa' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('evaluation_type.descrizione') }}>Tipo { orderField == 'evaluation_type.descrizione' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('sopralluogo_referente') }}>Referente { orderField == 'sopralluogo_referente' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('indirizzo.comune') }}>Comune { orderField == 'indirizzo.comune' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('indirizzo.provincia') }}>Prov { orderField == 'indirizzo.provincia' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('user.etichetta') }}>Cliente { orderField == 'user.etichetta' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th>In carico a</th>
                      <th>Stato</th>
                      <th>Tag</th>
                      <th>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    { evaluations.length ? evaluations.map((evaluation) => {
                        return <tr key={ evaluation.id }>
                                <td>{ evaluation ? evaluation.id_commessa : '-' }</td>
                                <td>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '-' }</td>
                                <td>{ evaluation ? evaluation.sopralluogo_referente : '-' }</td>
                                <td>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</td>
                                <td>{ evaluation && evaluation.province ? evaluation.province.sigla : '-' }</td>
                                <td>{ evaluation && evaluation.user ? evaluation.user.etichetta : '-' }</td>
                                <td>{ evaluation && evaluation.in_carico ? evaluation.in_carico.nome+' '+evaluation.in_carico.cognome : '-' }</td>
                                <td><span className='eval_state_label'>{ evaluation && evaluation.stato && evaluation.stato.descrizione ? evaluation.stato.descrizione : '-' }</span></td>
                                <td style={{'textAlign':'center'}}>
                                  { evaluation && evaluation.stato && evaluation.stato.descrizione && evaluation.stato.descrizione.toLowerCase() == 'sospesa' ?
                                    <div className='tag suspended'></div>
                                  : '' }
                                </td>
                                {/* <td>{ evaluation.created_at ? evaluation.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }</td> */}
                                <td className="actions" style={{'textAlign':'right','width': ['superadmin','back-a'].includes(authobj.user.role.slug) ? '250px' : '100px'}}>
                                  {/* RIEPILOGO */}
                                  <Button onClick={() => { navigate('/evaluations/det', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className='eval_recap'>
                                    <DynamicIcon iconName='FaEye' />
                                    <Tooltip anchorSelect=".eval_recap" place="top" variant="info">Overview perizia</Tooltip>
                                  </Button>
                                  {/* MODIFICA PRATICA */}
                                  { ['superadmin','back-a'].includes(authobj.user.role.slug) ?
                                    <Button onClick={() => { navigate('/evaluations/add', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className='eval_add'>
                                      <DynamicIcon iconName='FaPen' data-tooltip-id="eval_add" />
                                      <Tooltip anchorSelect=".eval_add" place="top" variant="info">Modifica perizia</Tooltip>
                                    </Button>
                                  : '' }
                                  { ['superadmin','tec','back-a'].includes(authobj.user.role.slug) ?
                                    <>
                                      {/* APPUNTAMENTO */}
                                      <Button onClick={() => { handleAppointment(evaluation.id) }} className={ evaluation.stato.id >= 4 ? 'eval_app disabled' : 'eval_app' }>
                                        <DynamicIcon iconName='FaCalendarAlt' />
                                        <Tooltip anchorSelect=".eval_app" place="top" variant="info">Gestisci appuntamento</Tooltip>
                                      </Button>
                                      {/* RENDICONTAZIONE */}
                                      <Button onClick={() => { navigate('/evaluations/reg', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={ evaluation.stato.id != 4 ? 'eval_reg disabled' : 'eval_reg' }>
                                        <DynamicIcon iconName='BiSolidPencil' />
                                        <Tooltip anchorSelect=".eval_reg" place="top" variant="info">Rendiconta perizia</Tooltip>
                                      </Button>
                                    </>
                                  : '' }
                                  { ['qual-cont'].includes(authobj.user.role.slug) ?
                                    <>
                                      {/* ASSEGNA A UTENTE */}
                                      <Button onClick={() => { handleAssignment(evaluation.id, evaluation.id_commessa) }} className={ evaluation.quality_cont_id ? 'eval_ass disabled' : 'eval_ass' }>
                                        <DynamicIcon iconName='FaCheck' data-tooltip-id="eval_add" />
                                        <Tooltip anchorSelect=".eval_ass" place="top" variant="info">Prendi in carico perizia</Tooltip>
                                      </Button>
                                      {/* QUALITA - CONTROLLO */}
                                      <Button onClick={() => { navigate('/evaluations/con', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={ (evaluation.stato.id != 5 || !evaluation.quality_cont_id) ? 'eval_con disabled' : 'eval_con' }>
                                        <DynamicIcon iconName='FaPen' data-tooltip-id="eval_con" />
                                        <Tooltip anchorSelect=".eval_con" place="top" variant="info">Controlla perizia</Tooltip>
                                      </Button>
                                    </>
                                  : '' }
                                  { ['qual-val'].includes(authobj.user.role.slug) ?
                                    <>
                                      {/* ASSEGNA A UTENTE */}
                                      <Button onClick={() => { handleAssignment(evaluation.id, evaluation.id_commessa) }} className={ evaluation.quality_val_id ? 'eval_ass disabled' : 'eval_ass' }>
                                        <DynamicIcon iconName='FaCheck' data-tooltip-id="eval_add" />
                                        <Tooltip anchorSelect=".eval_ass" place="top" variant="info">Prendi in carico perizia</Tooltip>
                                      </Button>
                                      {/* QUALITA - VALIDAZIONE */}
                                      <Button onClick={() => { navigate('/evaluations/val', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={ (evaluation.stato.id != 6 || !evaluation.quality_val_id) ? 'eval_val disabled' : 'eval_val' }>
                                        <DynamicIcon iconName='FaPen' data-tooltip-id="eval_val" />
                                        <Tooltip anchorSelect=".eval_val" place="top" variant="info">Valida perizia</Tooltip>
                                      </Button>
                                    </>
                                  : '' }
                                  {/* SOSPENSIONE / REWORK */}
                                  { !((['qual-cont'].includes(authobj.user.role.slug) && !evaluation.quality_cont_id) || (['qual-val'].includes(authobj.user.role.slug) && !evaluation.quality_val_id)) ?
                                    <Button onClick={() => { evaluation && evaluation.stato && evaluation.stato.slug && evaluation.stato.slug === 'sospesa' ? handleRework(evaluation.id) : handleSuspension(evaluation.id); }} className='eval_susp'>
                                      <DynamicIcon iconName='FaHandPaper' />
                                      <Tooltip anchorSelect=".eval_susp" place="top" variant="info">Sospendi / Rilavora perizia</Tooltip>
                                    </Button>
                                  : '' }
                                  {/* ANNULLAMENTO */}
                                  { ['superadmin','back-a'].includes(authobj.user.role.slug) ?
                                    <Button onClick={() => { handleDelete(evaluation.id, evaluation.id_commessa) }} className="delete eval_del">
                                      <DynamicIcon iconName='FaTrash' />
                                      <Tooltip anchorSelect=".eval_del" place="top" variant="info">Annulla perizia</Tooltip>
                                    </Button>
                                  : '' }
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna pratica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Evaluations;