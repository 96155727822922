import React, { useState, useContext, useEffect } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function useHelpers() {
    // AUTH, STATE, NAVIGATION
    const { authobj, setAuthobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
    let navigate = useNavigate();
    // DATA
    const [suspensionMotifs, setSuspensionMotifs] = useState([]);
    // SWEETALERT
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        axios.post(window.SERVER_URL+'popup_motifs/list', { sezione: 'evaluation_suspended' }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
            console.log(res.data);
            setSuspensionMotifs(res.data.motifs);
        });
    }, []);

    const updateUserState = (showPopup) => {
        axios.post(window.SERVER_URL+'users/sla_late', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
            console.log(res.data);
            let tmp_authobj = authobj;
            tmp_authobj.is_late = res.data.is_late;
            tmp_authobj.count_late = res.data.count_late;
            setAuthobj(tmp_authobj);
            if (showPopup)
              handleUserState();
        });
    }

    const handleUserState = () => {
      if (authobj.user.role.slug == 'tec') {
        if (authobj.user.stato_note) {
            Swal.fire({
              icon: 'warning',
              title: "Il tuo utente è sospeso",
              html: "<b>"+authobj.user.stato_note+"</b>",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              // cancelButtonColor: '#d33',
            }).then((result) => {
              navigate('/technicians/add', { state: { technician_id: authobj.user.id } });
            });
        }
    
        if (authobj.is_late > 0) {
            const { value: formValues } = Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                        <b>ATTENZIONE!</b><br>
                        <p>Ci sono <b>${authobj.count_late} pratiche</b> a te assegnate che risultano avere delle anomalie.<br>
                        Risolvi la situazione per poter accedere alla dashboard.</p>
                        `,
                width: '800px',
                showDenyButton: false,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Vai alle pratiche con anomalie",
                confirmButtonColor: "#B13905",
                denyButtonColor: "#12275d",
                cancelButtonColor: "#12275d"
            }).then((result) => {
                navigate('/dashboard/late');
            });
        }
      }
    }

    const geocode_point = (address) => {
        return axios.get(window.GMAPS_GEOCODE_URL+address+'&key='+window.GMAPS_APIKEY);
    }

    const handleAppointment = (evaluation_id) => {
        axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
    
          let apps = res.data.evaluation.appointments;
          let tab_app = '<table id="tab_app">';
          apps.forEach((app, index) => {
            tab_app += `<tr class="${index == (apps.length - 1) ? 'last' : ''}">
                          <td style="text-align: left; width: 120px;">${app.data_appuntamento}</td>
                          <td style="text-align: left;">${app.ora_appuntamento.substr(0, 5)}</td>
                          <td style="text-align: left;" class="note">${app.note ? app.note : ''}</td>
                          <td style="text-align: right;">${app.deleted_at ? '<b style="color: #B13905;">Cancellato</b>' : (app.svolto == 1 ? '<b style="color: #12275D;">Svolto</b>' : '<b style="color: green;">Da fare</b>')}</td>
                        </tr>`;
          });
          tab_app += '</table>';
    
          let tab_app_edit = `<table>
            <tr>
              <td class="left">
                <p>Nome<br><b>${res.data.evaluation.sopralluogo_referente}</b></p>
                <p>Telefono<br><b>${res.data.evaluation.sopralluogo_telefono_1}</b></p>
                <p>Cellulare<br><b>${res.data.evaluation.sopralluogo_cellulare}</b></p>
                <p>E-mail<br><b>${res.data.evaluation.sopralluogo_email}</b></p>
              </td>
              <td class="left">
                <table style="margin: 0; width: 100% !important;">
                  <tr style="border: 0 !important;">
                    <td style="padding-left: 0 !important;">
                      Data<br>
                      <input id="appointment_date" name="appointment_date" type="date" min="${new Date().toISOString().slice(0, 10)}"><br>
                    </td>
                    <td style="padding-right: 0 !important;">
                      Ora<br>
                      <input id="appointment_hour" name="appointment_hour" type="time"><br>
                    </td>
                  </tr>
                </table>
                Note<br>
                <textarea id="appointment_note" name="appointment_note"></textarea>
              </td>
            </tr>
          </table>`;
    
          const { value: formValues } = Swal.fire({
            html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 5.png"><br><h2>Fissa o gestisci l'appuntamento</h2><br>
                  ${tab_app}
                  ${!res.data.evaluation.appointment || res.data.evaluation.appointment.svolto == 0 ? tab_app_edit : ''}
                  `,
            width: '800px',
            preConfirm: () => {
              return [
                document.getElementById("appointment_date").value,
                document.getElementById("appointment_hour").value,
                document.getElementById("appointment_note").value
              ];
            },
            showDenyButton: (res.data.evaluation.appointment && res.data.evaluation.appointment.svolto == 0 ? true : false),
            showCancelButton: false,
            showConfirmButton: (res.data.evaluation.appointment && res.data.evaluation.appointment.svolto == 1 ? false : true),
            confirmButtonText: "Fissa l'appuntamento",
            denyButtonText: "Segna come svolto",
            confirmButtonColor: "#12275d",
            denyButtonColor: "#12275d",
            cancelButtonColor: "#12275d"
          }).then((result) => {
            // console.log(result);
            // console.log(JSON.stringify(formValues));
            if (result.isConfirmed) {
              console.log("DATA APPUNTAMENTO: "+result.value[0]);
              console.log("ORA APPUNTAMENTO: "+result.value[1]);
              console.log("NOTE APPUNTAMENTO: "+result.value[2]);
              // salva appuntamento
              axios.post(window.SERVER_URL+'appointments/save', { evaluation_id, 'data_appuntamento': result.value[0], 'ora_appuntamento': result.value[1], 'note': result.value[2] }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                console.log(res.data);
                if (res.data.success) {
                  Swal.fire({
                    'icon': 'success',
                    'title': 'Appuntamento salvato',
                    'text': 'Nuovo appuntamento registrato correttamente'
                  });
                  setGlobalRefresh();
                } else {
                  Swal.fire({
                    'icon': 'danger',
                    'title': 'Errore salvataggio',
                    'text': "Errore durante il salvataggio dell'appuntamento"
                  });
                }
              });
            } else if (result.isDenied) {
              // fissa come svolto
              axios.post(window.SERVER_URL+'appointments/set_done', { evaluation_id }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                console.log(res.data);
                if (res.data.success) {
                  Swal.fire({
                    'icon': 'success',
                    'title': 'Appuntamento svolto',
                    'text': "L'appuntamento è stato impostato come svolto"
                  }).then((result) => {
                    // getPage(
                    //   state && state.page ? state.page : page,
                    //   state && state.query ? state.query : query
                    // );
                    setGlobalRefresh();
                  });
    
                } else {
                  Swal.fire({
                    'icon': 'danger',
                    'title': 'Errore salvataggio',
                    'text': "Impossibile impostare l'appuntamento come svolto"
                  });
                }
              });
            }
          });
        });
    }

    const handleRework = (evaluation_id) => {
        axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
            console.log(res.data);
            let tmp_eval = res.data.evaluation;
        
            const { value: formValues } = Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                        <h2>GESTIONE SOSPENSIONE</h2><br>
                        <table><tr>
                            <td class="left" style="width: 50%; line-height: 24px; vertical-align: top;">
                                <b>Owner</b><br>
                                ${tmp_eval.in_carico ? tmp_eval.in_carico.nome : ''} ${tmp_eval.in_carico ? tmp_eval.in_carico.cognome : ''}
                                <br><br>
                                <b>Motivo</b><br>
                                ${tmp_eval.notebooks[0].popup_motif.descrizione}
                                <br><br>
                                <b>Note aggiuntive</b><br>
                                ${tmp_eval.notebooks[0].note}
                            </td>
                            <td class="left" style="width: 50%; line-height: 24px; vertical-align: top;">
                                <b>Data e ora</b><br>
                                ${tmp_eval.notebooks[0].created_at.substr(0, 10).split("-").reverse().join("/")} ${tmp_eval.notebooks[0].created_at.substr(11, 5)}
                                <br><br>
                                <b>Note per il rework</b><br>
                                <textarea id="rework_note" name="suspension_note" placeholder="Inserisci note aggiuntive"></textarea>
                            </td>
                        </tr></table>
                        <br><br>
                        `,
                width: '800px',
                preConfirm: () => {
                    return [
                        document.getElementById("rework_note").value
                    ];
                },
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Rilavora pratica",
                cancelButtonText: "Ci ho ripensato",
                confirmButtonColor: "#12275d",
                denyButtonColor: "#12275d",
                cancelButtonColor: "#12275d"
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(window.SERVER_URL+'evaluations/set_unsuspended', { id: evaluation_id, popup_motif_id: 13, note: result.value[0] }, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                    }}).then(res => {
                    console.log(res.data);
                    if (res.data.success) {
                        Swal.fire({
                            'icon': 'success',
                            'title': 'Perizia rimessa in lavorazione',
                            'text': 'La perizia è stata rimessa in lavorazione correttamente'
                        });
                        setGlobalRefresh();
                        navigate('/dashboard');
                    } else {
                        Swal.fire({
                            'icon': 'danger',
                            'title': 'Errore',
                            'text': "Impossibile rimettere la perizia in lavorazione"
                        });
                    }
                    });
                }
            });
        });
    }

    const handleSuspension = (evaluation_id) => {
        let popup_select = '<select id="sospensione_motivo" name="sospensione_motivo">';
        popup_select += '<option value="">- Seleziona la motivazione -</option>';
        if (suspensionMotifs) {
            suspensionMotifs.forEach((opt) => {
                popup_select += '<option value="'+opt.id+'">'+opt.descrizione+'</option>';
            });
        }
        popup_select += '</select>';

        const { value: formValues } = Swal.fire({
            html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                    <b>ATTENZIONE!</b><br>
                    <p>Devi indicare il motivo per cui vuoi sospendere la perizia; descrivi bene la situazione.</p>
                    ${popup_select}                 
                    <textarea id="suspension_note" name="suspension_note" placeholder="Motivazione sospensione"></textarea>
                    <br><br>
                    `,
            width: '800px',
            preConfirm: () => {
                return [
                    document.getElementById("sospensione_motivo").value,
                    document.getElementById("suspension_note").value
                ];
            },
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Sospendi la perizia",
            cancelButtonText: "Ci ho ripensato",
            confirmButtonColor: "#12275d",
            denyButtonColor: "#12275d",
            cancelButtonColor: "#12275d"
        }).then((result) => {
            if (result.isConfirmed) {
                // sospendi la perizia
                axios.post(window.SERVER_URL+'evaluations/set_suspended', { id: evaluation_id, popup_motif_id: result.value[0], note: result.value[1] }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                console.log(res.data);
                if (res.data.success) {
                    Swal.fire({
                        'icon': 'success',
                        'title': 'Perizia sospesa',
                        'text': 'La perizia è stata sospesa correttamente'
                    });
                    setGlobalRefresh();
                    navigate('/dashboard');
                } else {
                    Swal.fire({
                        'icon': 'danger',
                        'title': 'Errore',
                        'text': "Impossibile impostare la perizia come sospesa"
                    });
                }
                });
            }
        });
    }

    return { handleUserState, updateUserState, handleAppointment, handleSuspension, handleRework, geocode_point };
}