import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// AXIOS
import axios from 'axios';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Calendar() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  // DATA
  const [currentDate, setCurrentDate] = useState(new Date());
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    fetchTodos();
  }, [currentDate]);

  const fetchTodos = async () => {
    const formattedDate = currentDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    axios.post(window.SERVER_URL+'appointments/get_day', { data: formattedDate }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setTodos(res.data.appointments);
    });
  };

  const handlePrevDay = () => {
    const prevDate = new Date(currentDate);
    prevDate.setDate(prevDate.getDate() - 1);
    setCurrentDate(prevDate);
  };

  const handleToday = () => {
    const thisDate = new Date();
    setCurrentDate(thisDate);
  };

  const handleNextDay = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setCurrentDate(nextDate);
  };

  const italianMonths = [
    "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
  ];
  const italianDays = [
    "Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"
  ];
  
  const getItalianMonthName = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return italianMonths[date.getMonth()];
  };
  
  const getItalianDayName = (dateString) => {
    const date = new Date(dateString);
    const dayIndex = date.getDay();
    return italianDays[dayIndex];
  };

  return (
    <div className="calendar">
      <div className="header">
        <h2>
          { getItalianMonthName(currentDate.toISOString().split('T')[0]) }
          <button className='forward' onClick={handleNextDay}><DynamicIcon iconName='IoIosArrowForward' /></button>
          <button className='today' onClick={handleToday}><DynamicIcon iconName='MdToday' /></button>
          <button className='back' onClick={handlePrevDay}><DynamicIcon iconName='IoIosArrowBack' /></button>
        </h2>
        <p className='date'>
          { getItalianDayName(currentDate.toISOString().split('T')[0]) }&nbsp;
          { `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}` }
        </p>
      </div>
      <div className="day">
        {Array.from({ length: 24 }, (_, i) => {
          if (i >= 8 && i <= 20) {
            return (
              <div className='hour' key={i}>
                <h3>{`${i}:00`}</h3>
                { todos.map((todo) => {
                  if (i == todo.ora_appuntamento.substr(0,2)) {
                    return (
                      <div className='appointment'>
                        <p className='title'>
                          Sopralluogo ID { todo.evaluation.id_commessa }
                          <span>
                            <img src={`${process.env.PUBLIC_URL}/gfx/Tracciato 262.png`} />
                            { todo.evaluation.assets_count } beni
                          </span>
                        </p>
                        <p className='info'>
                          <span>{ todo.ora_appuntamento.substr(0,5) }</span> -&nbsp;
                          { todo.evaluation ? todo.evaluation.indirizzo : '' }
                          { todo.evaluation ? ', '+todo.evaluation.civico : '' }<br />
                          { todo.evaluation && todo.evaluation.town ? todo.evaluation.town.nome : '' }&nbsp;
                          { todo.evaluation && todo.evaluation.province ? '('+todo.evaluation.province.sigla+')' : '' }
                        </p>
                        <p className='contact'>
                          { todo.evaluation ? todo.evaluation.sopralluogo_referente : '' }<br />
                          <span className='mode'><DynamicIcon iconName='BsTelephoneFill' /> <span>{ todo.evaluation ? todo.evaluation.sopralluogo_telefono_1 : '' }</span></span>
                          <span className='mode'><DynamicIcon iconName='BsTelephoneFill' /> <span>{ todo.evaluation ? todo.evaluation.sopralluogo_telefono_2 : '' }</span></span>
                          <span className='mode'><DynamicIcon iconName='MdSmartphone' /> <span>{ todo.evaluation ? todo.evaluation.sopralluogo_cellulare : '' }</span></span>
                        </p>
                      </div>
                    );
                  }
                }) }
                {/* { todos.filter(todo => {
                    const todoDate = new Date(todo.date);
                    return todoDate.getHours() === i;
                  }).map(todo => (
                    <div key={todo.id}>{todo.title}</div>
                  ))} */}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Calendar;