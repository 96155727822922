import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function DashboardLate() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleAppointment, updateUserState, handleUserState } = useHelpers();
  // DATA
  const [evaluations, setEvaluations] = useState(null);

  const getIconByRule = (rolename) => {
    switch (rolename) {
      case 'backoffice':
        return 'logo_backoffice.png';
      break;
      case 'tec':
        return 'logo_user_tecnico.png';
      break;
      case 'qual-cont':
        return 'logo_user_qualita1.png';
      break;
      case 'qual-val':
        return 'logo_user_qualita2.png';
      break;
      case 'cliente':
        return 'logo_user_cliente.png';
      break;
      default:
        return 'logo_backoffice.png';
      break;
    }
  }

  useEffect(() => {
    updateUserState(false);
    axios.post(window.SERVER_URL+'evaluations/sla_late', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setEvaluations(res.data);
    });
  }, [globalRefresh]);
  
  return (
    <Container className="content dashboard">
      <Row className="page_title">
        <Col>
          <img style={{'height':'68px'}} src={`${process.env.PUBLIC_URL}/gfx/${ getIconByRule(authobj.user.role.slug.toLowerCase()) }`} />
          <p style={{'fontSize':'36px'}} className='greeting'>Pratiche con anomalie ({ authobj.count_late })</p>
          <br /><br />
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <h2 className='underline'>
            <img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-13.png`} style={{'display':'inline-block','marginRight':'10px'}} />
            Sopralluoghi non fissati
          </h2>
          { evaluations && evaluations.assegnata && evaluations.assegnata.length ?
            <div className='inner_wrapper reg'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID pratica</th>
                    <th>Tipo</th>
                    <th>Referente</th>
                    <th>Comune</th>
                    <th>Prov</th>
                    <th>Cliente</th>
                    <th>Anomalia SLA</th>
                    <th>Tag</th>
                    <th style={{'width':'100px'}}>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  { evaluations && evaluations.assegnata && evaluations.assegnata.map((evaluation) => {
                    return <tr key={ evaluation.id }>
                            <td>{ evaluation ? evaluation.id_commessa : '-' }</td>
                            <td>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '-' }</td>
                            <td>{ evaluation ? evaluation.sopralluogo_referente : '-' }</td>
                            <td>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</td>
                            <td>{ evaluation && evaluation.province ? evaluation.province.nome : '-' }</td>
                            <td>{ evaluation && evaluation.user ? evaluation.user.etichetta : '-' }</td>
                            <td>Scaduta da { evaluation && evaluation.sla ? (parseInt(evaluation.sla.giorni_trascorsi) - parseInt(evaluation.sla.giorni_disponibili)) : '-' } giorni</td>
                            <td style={{'textAlign':'center'}}>
                              { evaluation && evaluation.stato && evaluation.stato.descrizione && evaluation.stato.descrizione.toLowerCase() == 'sospesa' ?
                                <div className='tag suspended'></div>
                              : '' }
                            </td>
                            <td className="actions">
                              {/* RIEPILOGO */}
                              <Button onClick={() => { navigate('/evaluations/det', { state: { evaluation_id: evaluation.id }}) }} className='eval_recap'>
                                <DynamicIcon iconName='FaEye' />
                                <Tooltip anchorSelect=".eval_recap" place="top" variant="info">Overview perizia</Tooltip>
                              </Button>
                              {/* APPUNTAMENTO */}
                              <Button onClick={() => { handleAppointment(evaluation.id) }} className={ evaluation && evaluation.stato && evaluation.stato.id >= 4 ? 'eval_app disabled' : 'eval_app' }>
                                <DynamicIcon iconName='FaCalendarAlt' />
                                <Tooltip anchorSelect=".eval_app" place="top" variant="info">Gestisci appuntamento</Tooltip>
                              </Button>         
                            </td>
                          </tr>
                    }) }
                </tbody>
              </Table>
            </div>
          : <p style={{'margin':'-15px 10px 40px'}}>Non sono presenti pratiche con anomalie in questo stato</p> }

          <h2 className='underline' style={{'marginTop':'30px'}}>
            <img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-13.png`} style={{'display':'inline-block','marginRight':'10px'}} />
            Sopralluoghi scaduti
          </h2>
          { evaluations && evaluations.soprafis && evaluations.soprafis.length ?
            <div className='inner_wrapper reg'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID pratica</th>
                    <th>Tipo</th>
                    <th>Referente</th>
                    <th>Comune</th>
                    <th>Prov</th>
                    <th>Cliente</th>
                    <th>Anomalia SLA</th>
                    <th>Tag</th>
                    <th style={{'width':'100px'}}>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  { evaluations && evaluations.soprafis && evaluations.soprafis.map((evaluation) => {
                    return <tr key={ evaluation.id }>
                            <td>{ evaluation ? evaluation.id_commessa : '-' }</td>
                            <td>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '-' }</td>
                            <td>{ evaluation ? evaluation.sopralluogo_referente : '-' }</td>
                            <td>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</td>
                            <td>{ evaluation && evaluation.province ? evaluation.province.nome : '-' }</td>
                            <td>{ evaluation && evaluation.user ? evaluation.user.etichetta : '-' }</td>
                            <td>Scaduta da { evaluation && evaluation.sla ? (parseInt(evaluation.sla.giorni_trascorsi) - parseInt(evaluation.sla.giorni_disponibili)) : '-' } giorni</td>
                            <td style={{'textAlign':'center'}}>
                              { evaluation && evaluation.stato && evaluation.stato.descrizione && evaluation.stato.descrizione.toLowerCase() == 'sospesa' ?
                                <div className='tag suspended'></div>
                              : '' }
                            </td>
                            <td className="actions">
                              {/* RIEPILOGO */}
                              <Button onClick={() => { navigate('/evaluations/det', { state: { evaluation_id: evaluation.id }}) }} className='eval_recap'>
                                <DynamicIcon iconName='FaEye' />
                                <Tooltip anchorSelect=".eval_recap" place="top" variant="info">Overview perizia</Tooltip>
                              </Button>
                              {/* APPUNTAMENTO */}
                              <Button onClick={() => { handleAppointment(evaluation.id) }} className={ evaluation && evaluation.stato && evaluation.stato.id >= 4 ? 'eval_app disabled' : 'eval_app' }>
                                <DynamicIcon iconName='FaCalendarAlt' />
                                <Tooltip anchorSelect=".eval_app" place="top" variant="info">Gestisci appuntamento</Tooltip>
                              </Button>         
                            </td>
                          </tr>
                    }) }
                </tbody>
              </Table>
            </div>
          : <p style={{'margin':'-15px 10px 40px'}}>Non sono presenti pratiche con anomalie in questo stato</p> }

          <h2 className='underline'>
            <img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-13.png`} style={{'display':'inline-block','marginRight':'10px'}} />
            Caricamenti dati scaduti
          </h2>
          { evaluations && evaluations.sopraeff && evaluations.sopraeff.length ?
            <div className='inner_wrapper reg'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID pratica</th>
                    <th>Tipo</th>
                    <th>Referente</th>
                    <th>Comune</th>
                    <th>Prov</th>
                    <th>Cliente</th>
                    <th>Anomalia SLA</th>
                    <th>Tag</th>
                    <th style={{'width':'100px'}}>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  { evaluations && evaluations.sopraeff && evaluations.sopraeff.map((evaluation) => {
                    return <tr key={ evaluation.id }>
                            <td>{ evaluation ? evaluation.id_commessa : '-' }</td>
                            <td>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '-' }</td>
                            <td>{ evaluation ? evaluation.sopralluogo_referente : '-' }</td>
                            <td>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</td>
                            <td>{ evaluation && evaluation.province ? evaluation.province.nome : '-' }</td>
                            <td>{ evaluation && evaluation.user ? evaluation.user.etichetta : '-' }</td>
                            <td>Scaduta da { evaluation && evaluation.sla ? (parseInt(evaluation.sla.giorni_trascorsi) - parseInt(evaluation.sla.giorni_disponibili)) : '-' } giorni</td>
                            <td style={{'textAlign':'center'}}>
                              { evaluation && evaluation.stato && evaluation.stato.descrizione && evaluation.stato.descrizione.toLowerCase() == 'sospesa' ?
                                <div className='tag suspended'></div>
                              : '' }
                            </td>
                            <td className="actions">
                              {/* RIEPILOGO */}
                              <Button onClick={() => { navigate('/evaluations/det', { state: { evaluation_id: evaluation.id }}) }} className='eval_recap'>
                                <DynamicIcon iconName='FaEye' />
                                <Tooltip anchorSelect=".eval_recap" place="top" variant="info">Overview perizia</Tooltip>
                              </Button>
                              {/* RENDICONTAZIONE */}
                              <Button onClick={() => { navigate('/evaluations/reg', { state: { evaluation_id: evaluation.id }}) }} className={ evaluation && evaluation.stato && evaluation.stato.id != 4 ? 'eval_reg disabled' : 'eval_reg' }>
                                <DynamicIcon iconName='BiSolidPencil' />
                                <Tooltip anchorSelect=".eval_reg" place="top" variant="info">Rendiconta perizia</Tooltip>
                              </Button>
                            </td>
                          </tr>
                    }) }
                </tbody>
              </Table>
            </div>
          : <p style={{'margin':'-15px 10px 40px'}}>Non sono presenti pratiche con anomalie in questo stato</p> }
        </Col>
        <Col lg={3}>
          <h2 className='underline'>Sidebar</h2>
          <div style={{'minHeight':'800px','background':'#eee','padding':'20px','textAlign':'center','fontWeight':'700','fontSize':'30px'}}>SIDEBAR</div>
        </Col>
      </Row>
    </Container>
  );
  
}

export default DashboardLate;